
import React, {Component} from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import {Home} from "./pages/Home.js"
import {Signup} from "./pages/Signup.js"
import {Signin} from './pages/Signin.js'
import {Generator} from './pages/Generator.js'
import {About} from './pages/About.js'
import { NavigationBar } from './pages/Widgets.js';
import { Pricing } from './pages/Pricing.js';
import { Tools } from './pages/Tools.js';
import { Dashboard } from './pages/Dashboard.js';
function App() {
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <NavigationBar />
        </header>
        <div>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/signin' element={<Signin />} />
            <Route path='/generator' element={<Generator />} />
            <Route path='/about' element={<About />} />
            <Route path='/tools' element={<Tools />} />
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/dashboard' element={<Dashboard />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
