import './Home.css';
import React, { useState, useEffect } from 'react';
import {SignupButton} from './Widgets.js'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


export const Signin = () => {
const [username, setUsername] = useState('');
const [password, setPassword] = useState('');
const [error, setError] = useState('');

const navigate = useNavigate();
const handleCreateAccount = async () => {
  try {
    console.log('Attempting to sign you in...');
    
    const response = await fetch('http://localhost:8080/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
      const data = await response.json();
      console.error('Error signing in:', data.error);
      setError('Could not log you in')
    } else {
      const data = await response.json();
      console.log('users token is:', data.authToken);
      localStorage.setItem('authToken', data.authToken)
      navigate('/');
      // Perform any additional actions, such as navigating to the new channel details page
    }
  } catch (error) {
    console.error('Network error or other issue:', error.message);
    setError("Could not log you in")
  }
};
  return (
    <div className="App">
      <header className="App-header">
      <form>
          <h1>Sign in</h1>
          <label>Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} name="name"/>
          </label>
          <br></br>
          <label>Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} name="description"/>
          </label>
          <br></br>
        </form>
        <button onClick={handleCreateAccount}>Sign in</button>
        <p>{error}</p>
        <hr></hr>
        <Link to = '/signup'>
          <button>Need an account? Create one here</button>
          </Link>
        
      </header>
    </div>
  );
}

