import './Home.css';
import React, { useState, useEffect } from 'react';
import {SignupButton} from './Widgets.js'
import { useNavigate } from 'react-router-dom';

export const Home = () => {
const [buttons, setButtons] = useState([]);

var navigate = useNavigate();

function onToGenerator() {
  navigate("/generator");
}

  useEffect(() => {


    const checkToken = async () => {

    try {
      console.log(localStorage.getItem('authToken'))
      const response = await fetch('http://localhost:8080/api/protected', {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authToken'),
      },
    });
      if (!response.ok) {
        navigate('/signup')
      }
      else{
        console.log('Authenticated')
      }
      
    } catch (error) {
      console.error('Error fetching channels:', error.message);
    }
  };
    checkToken();
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <div className="HeaderBar">
        </div>
        <h1>QR Code Generator</h1>
        <button onClick={onToGenerator}>Take me there!</button> 
      </header>
    </div>
  );
}


