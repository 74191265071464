import './Home.css';
import React, { useState, useEffect } from 'react';


export const Dashboard = () => {
    return (
        <div>
            <h1>Dashboard</h1>
            <p>hello hello
            </p>
        </div>
    );
};

