import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode';
import { QRCodeParams, generateQRCode, GenerationModes} from './Widgets.js'
import { WifiQuery, URLQuery, VCardQuery, LocationQuery, EventQuery, TextQuery} from './Queries';
import urlImage from '../resources/url.png'
import wifiImage from '../resources/wifi.png'
import cardImage from '../resources/user.png'
import locationImage from '../resources/marker.png'
import calendarImage from '../resources/calendar-clock.png'
import textImage from '../resources/poll-h.png'

export const Generator = (props) => {
    const [svgCode, setSvgCode] = useState('');
    const [activeButton, setActiveButton] = useState('');
    const [generationMode, setGenerationMode] = useState('');

    useEffect(() => {

      }, []);

    const getQRCode = async () => {
        // try {
        //     // generates url based qr code
        //     if(generationMode == 0){
        //         const svg = await QRCode.toString(document.getElementsByName("url")[0].value, { type: 'svg' });
        //     }
        //     // generates wifi based qr code, change WPA for more network types
        //     else if(generationMode == 1){
        //         const svg = await QRCode.toString('WIFI:S:' + document.getElementsByName("network-name")[0].value + ';T:WPA;P:' + document.getElementsByName("network-pass")[0].value + ';;', { type: 'svg' });
        //     }
        //     setSvgCode(svg);
        //     drawQRCodeOnCanvas(svg);
        //     document.getElementById('downloadButton').style.display = 'block';
        // } catch (err) {
        //     console.error(err);
        // }

        try {
            // generates url based qr code
            const svg = await generateQRCode(getParams())
            console.log(svg)
            setSvgCode(svg);
            drawQRCodeOnCanvas(svg);
            document.getElementById('downloadButton').style.display = 'inline-block';
            document.getElementById('saveButton').style.display = 'inline-block';
            document.getElementById('complete-label').style.display = 'block';
            document.getElementById('qr-canvas').style.display = 'inline-block';
            
        } catch (err) {
            console.error(err);
        }
    };

    const getParams = () => {
        return new QRCodeParams(
            generationMode,
            document.getElementsByName("url")[0].value,
            document.getElementsByName("wifi-name")[0].value,
            document.getElementsByName("wifi-type")[0].value,
            document.getElementsByName("wifi-pass")[0].value,
            document.getElementsByName("fname")[0].value,
            document.getElementsByName("lname")[0].value,
            document.getElementsByName("org")[0].value,
            document.getElementsByName("title")[0].value,
            document.getElementsByName("street-address")[0].value,
            document.getElementsByName("city")[0].value,
            document.getElementsByName("region")[0].value,
            document.getElementsByName("postal-code")[0].value,
            document.getElementsByName("country")[0].value,
            document.getElementsByName("phone-cell")[0].value,
            document.getElementsByName("phone-work")[0].value,   
            document.getElementsByName("email")[0].value,
            document.getElementsByName("site")[0].value,
            document.getElementsByName("location")[0].value,
            document.getElementsByName("event-summary")[0].value,
            document.getElementsByName("start-date")[0].value,
            document.getElementsByName("end-date")[0].value,
            document.getElementsByName("text")[0].value       
          );
    }

    const drawQRCodeOnCanvas = async (svg) => {
        const canvas = document.getElementById('qr-canvas');
        const context = canvas.getContext('2d');
        const image = new Image();
        const svgData = `data:image/svg+xml;base64,${window.btoa(svg)}`;

        image.onload = () => {
            context.clearRect(0, 0, canvas.width, canvas.height);
            canvas.width = 300;
            canvas.height = 300;
            context.drawImage(image, 0, 0);
            console.log('Image displayed');
            adjustFormHeight();
        };

        image.src = svgData;
    };

    const initiateDownload = () => {
        const canvas = document.getElementById('qr-canvas');
        const link = document.createElement('a');
        link.download = 'qrcode.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
    };

    const hideElement = (elementID) => {
        document.getElementById(elementID).style.height = '0px';

    }

    const showElement = (elementID) => {
        document.getElementById(elementID).style.height = 'fit-content';
    }

    const adjustFormHeight = () => {
            document.getElementById('form-box').style.height = '' + (document.getElementById("url-query").getBoundingClientRect().height + document.getElementById("wifi-query").getBoundingClientRect().height + document.getElementById("vcard-query").getBoundingClientRect().height + document.getElementById("location-query").getBoundingClientRect().height + document.getElementById("event-query").getBoundingClientRect().height + document.getElementById("text-query").getBoundingClientRect().height + document.getElementById("qr-canvas").getBoundingClientRect().height + document.getElementById("complete-label").getBoundingClientRect().height + document.getElementById("fields-label").getBoundingClientRect().height) + 'px';
    }

    const changeQRType = (index) => {

        if(index === GenerationModes.URL){
            setGenerationMode(0);
            showElement('url-query');
            hideElement('wifi-query');
            hideElement('vcard-query');
            hideElement('location-query');
            hideElement('event-query');
            hideElement('text-query');
            adjustFormHeight();
            setActiveButton('url');
        } 
        else if(index === GenerationModes.WIFI){
            setGenerationMode(1);
            hideElement('url-query');
            showElement('wifi-query');
            hideElement('vcard-query');
            hideElement('location-query');
            hideElement('event-query');
            hideElement('text-query');
            adjustFormHeight();
            setActiveButton('wifi');
        }
        else if(index === GenerationModes.VCARD){
            setGenerationMode(2);
            hideElement('url-query');
            hideElement('wifi-query');
            showElement('vcard-query');
            hideElement('location-query');
            hideElement('event-query');
            hideElement('text-query');
            adjustFormHeight();
            setActiveButton('vcard')
        }
        else if(index === GenerationModes.LOCATION){
            setGenerationMode(3);
            hideElement('url-query');
            hideElement('wifi-query');
            hideElement('vcard-query');
            showElement('location-query');
            hideElement('event-query');
            hideElement('text-query');
            adjustFormHeight();
            setActiveButton('location')
        }
        else if(index === GenerationModes.EVENT){
            setGenerationMode(4);
            hideElement('url-query');
            hideElement('wifi-query');
            hideElement('vcard-query');
            hideElement('location-query');
            showElement('event-query');
            hideElement('text-query');
            adjustFormHeight();
            setActiveButton('event')
        }
        else if(index === GenerationModes.TEXT){
            setGenerationMode(5);
            hideElement('url-query');
            hideElement('wifi-query');
            hideElement('vcard-query');
            hideElement('location-query');
            hideElement('event-query');
            showElement('text-query');
            adjustFormHeight();
            setActiveButton('text')
        }
    }
    // this needs to be used when creating a qr code for wifi
    //WIFI:S:networkname;T:WPA;P:password;;

    return (
        <div>
            <br></br>
            <br></br>
            <h1 style={{color: 'black'}}>QR Codes to Take You Anywhere</h1>
            <div id="qr-block">
            <div id="button-holder">
                <li style={{display: 'inline-block'}}><button id="qr-selector-button" className={activeButton === 'url' ? 'selector-active' : 'selector-inactive'} onClick={() => changeQRType(0)}><img style={{maxWidth: '30px'}} src={urlImage}/><br></br> URL</button></li>
                <li style={{display: 'inline-block'}}><button id="qr-selector-button" className={activeButton === 'wifi' ? 'selector-active ': 'selector-inactive'} onClick={() => changeQRType(1)}><img style={{maxWidth: '30px'}} src={wifiImage}/><br></br> Wifi</button></li>
                <li style={{display: 'inline-block'}}><button id="qr-selector-button" className={activeButton === 'vcard' ? 'selector-active' : 'selector-inactive'} onClick={() => changeQRType(2)}><img style={{maxWidth: '30px'}} src={cardImage}/><br></br>Contact Card</button></li>
                <li style={{display: 'inline-block'}}><button id="qr-selector-button" className={activeButton === 'location' ? 'selector-active' : 'selector-inactive'} onClick={() => changeQRType(3)}><img style={{maxWidth: '30px'}} src={locationImage}/><br></br>Location</button></li>
                <li style={{display: 'inline-block'}}><button id="qr-selector-button" className={activeButton === 'event' ? 'selector-active' : 'selector-inactive'} onClick={() => changeQRType(4)}><img style={{maxWidth: '30px'}} src={calendarImage}/><br></br>Event</button></li>
                <li style={{display: 'inline-block'}}><button id="qr-selector-button" className={activeButton === 'text' ? 'selector-active' : 'selector-inactive'} onClick={() => changeQRType(5)}><img style={{maxWidth: '30px'}} src={textImage}/><br></br>Text</button></li>
            </div>
            <div id="form-box">
            <h3 id="fields-label">Fill in the fields below</h3>
                <h3 id="complete-label" style={{display: 'none'}}>Here it is!</h3>
                <canvas id="qr-canvas" style={{display: 'none'}}></canvas>
                <div className={activeButton === 'url' ? 'query-active' : 'query-inactive'} >
                    <URLQuery></URLQuery>
                </div>
                <div className={activeButton === 'wifi' ? 'query-active' : 'query-inactive'} >
                    <WifiQuery></WifiQuery>
                </div>
                <div className={activeButton === 'vcard' ? 'query-active' : 'query-inactive'} >
                    <VCardQuery></VCardQuery>
                </div>
                <div className={activeButton === 'location' ? 'query-active' : 'query-inactive'} >
                    <LocationQuery></LocationQuery>
                </div>
                <div className={activeButton === 'event' ? 'query-active' : 'query-inactive'} >
                    <EventQuery></EventQuery>
                </div>
                <div className={activeButton === 'text' ? 'query-active' : 'query-inactive'} >
                    <TextQuery></TextQuery>
                </div>
            </div>
            <div>
            <button id='generate-button' onClick={getQRCode}>Generate QR Code</button>
            </div>
            <div>
            <button
                style={{ display: 'none' }}
                id="downloadButton"
                onClick={initiateDownload}
            >
                Download QR Code as PNG
            </button>
            <button
                style={{ display: 'none' }}
                id="saveButton"
                onClick={() => {navigator.clipboard.writeText(svgCode); alert('Copied to Clipboard')}}
            >
                Copy QR Code as SVG Code
            </button>
            </div>
            </div>
  

        </div>
    );
};