import './Home.css';
import React, { useState, useEffect } from 'react';
import {ChannelDisplay, ChannelButton, CreateChannelButton, SignupButton} from './Widgets.js'
import { BrowserRouter as Router, Routes, Route, Link, redirect , useNavigate} from 'react-router-dom';


export const Signup = () => {
const [username, setUsername] = useState('');
const [password, setPassword] = useState('');
const [error, setError] = useState('');

const navigate = useNavigate();
const handleCreateAccount = async () => {
  try {
    console.log('Attempting to create a new user...');
    
    const response = await fetch('http://localhost:8080/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
      const data = await response.json();
      console.error('Error registering user:', data.error);
    } else {
      const data = await response.json();
      navigate('/signin')
      // Perform any additional actions, such as navigating to the new channel details page
    }
  } catch (error) {
    console.error('Network error or other issue:', error.message);
  }
};
  return (
    <div className="App">
      <header className="App-header">
      <form>
          <h1>Sign up</h1>
          <label>Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} name="name"/>
          </label>
          <br></br>
          <label>Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} name="description"/>
          </label>
          <br></br>
        </form>
        <button onClick={handleCreateAccount}>Create Account</button>
        <hr></hr>
        <Link to = '/signin'>
          <button>Already have an account? Login here</button>
          </Link>
        
      </header>
    </div>
  );
}

