import "../Nav.css"
import aselogo from '../resources/aselogo.png';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import QRCode from 'qrcode'
import React from "react";

export const SignupButton = () =>{

  var navigate = useNavigate();
  const logout = () => {
      localStorage.setItem('authToken', '')
      navigate('/signin')
  }

  return(        
      <button onClick={logout} className="NewChannelButton">Log out</button>
  )
}

export const NavigationBar = () => {
  const navigate = useNavigate();
  
  const navigateTo = (path) => {
    navigate('/' + path);
  };

  return (
    <ul id='nav-list'>
      <div id='left-elements'>
        <li id='nav-element'>
          <img 
            id="header-image" 
            onClick={() => navigateTo('')} 
            src={aselogo} 
            alt="ASE Logo"
          />
        </li>
        <li id='nav-element'>
          <Link to='https://advancedsiteexposure.com'>
          <button href='https://advancedsiteexposure.com' id='web-button' className='web-button'>Need a Website?</button>
          </Link>
        </li>
      </div>
      <div id='right-elements'>
        <li id='nav-element' style={{ float: 'right' }}>
          <button id='nav-button-primary' onClick={() => navigateTo('about')}>Why ASE?</button>
        </li>
        <li id='nav-element' style={{ float: 'right' }} className='dropdown'>
          <button id='nav-button-primary' onClick={() => navigateTo('tools')}>
            Tools<i className='fa fa-caret-down'></i>
          </button>
          <div className='dropdown-content'>
            <button onClick={() => navigateTo('generator')} id='nav-button-dropdown'>QR Code Generator</button>
          </div>
        </li>
        <li id='nav-element' style={{ float: 'right' }}>
          <button id='nav-button-primary' onClick={() => navigateTo('pricing')}>Pricing</button>
        </li>
        <li id='nav-element' style={{ float: 'right' }}>
          <button id='nav-button-secondary' onClick={() => navigateTo('signin')}>Signin</button>
        </li>
      </div>
    </ul>
  );
};
export const generateQRCode = async (params) => {
  try {
    // generates url based qr code
    if(params.generationMode === GenerationModes.URL){
      if(params.url !== null || params.url !== '')
        return await QRCode.toString(params.url, { type: 'svg' });
    }
    // generates wifi based qr code, change WPA for more network types
    else if(params.generationMode === GenerationModes.WIFI){
        return await QRCode.toString('WIFI:S:' + params.networkName + ';T:' + params.networkType + ';P:' + params.networkPassword + ';;', { type: 'svg' });
    }
    else if(params.generationMode === GenerationModes.VCARD){
      return await QRCode.toString(getVCardString(params), { type: 'svg' });
    }
    else if(params.generationMode === GenerationModes.LOCATION){
      console.log(params.location)
      var loc = getLocationString(params.location)
      console.log(loc)
      return await QRCode.toString(loc, {type: 'svg'});
    }
    else if(params.generationMode === GenerationModes.EVENT){
      return await QRCode.toString(getVEventString(params), {type: 'svg'});
    }
    else if(params.generationMode === GenerationModes.TEXT){
      return await QRCode.toString(params.text, {type: 'svg'});
    }
} catch (err) {
    console.error(err);
}
}


const getVEventString = (params) => {
  var startDate = new Date(params.startDate);
  var endDate = new Date(params.endDate);
  var returnString = 'BEGIN:VEVENT\n'
  if(startDate.getUTCDate().toString() !== 'NaN'){
  var startDateString = 'DTSTART:' + startDate.getUTCFullYear().toString()
    + (startDate.getUTCMonth().toString().length === 2 ? startDate.getUTCMonth().toString() : '0' + startDate.getUTCMonth().toString())
    + (startDate.getUTCDate().toString().length === 2 ? startDate.getUTCDate().toString() : '0' + startDate.getUTCDate().toString())
    + 'T'
    + (startDate.getUTCHours().toString().length === 2 ? startDate.getUTCHours().toString() : '0' + startDate.getUTCHours().toString())
    + (startDate.getUTCMinutes().toString().length === 2 ? startDate.getUTCMinutes().toString() : '0' + startDate.getUTCMinutes().toString())
    + '00Z';
    returnString += startDateString + '\n';
  }
  if(endDate.getUTCDate().toString() !== 'NaN'){
  var endDateString = 'DTEND:' + startDate.getUTCFullYear().toString()
    + (endDate.getUTCMonth().toString().length === 2 ? endDate.getUTCMonth().toString() : '0' + endDate.getUTCMonth().toString())
    + (endDate.getUTCDate().toString().length === 2 ? endDate.getUTCDate().toString() : '0' + endDate.getUTCDate().toString())
    + 'T'
    + (endDate.getUTCHours().toString().length === 2 ? endDate.getUTCHours().toString() : '0' + endDate.getUTCHours().toString())
    + (endDate.getUTCMinutes().toString().length === 2 ? endDate.getUTCMinutes().toString() : '0' + endDate.getUTCMinutes().toString())
    + '00Z';
    returnString += endDateString + '\n';
  }
  returnString += 'SUMMARY:' + params.summary + '\n';
  returnString += 'END:VEVENT\n';
  console.log(returnString);
  return returnString;
}

const getVCardString = (params) => {
  // required params
  var returnString = 'BEGIN:VCARD\nVERSION:3.0;\n'

  // deals with both name formats
  if(params.firstName != null && params.firstName != '' && params.lastName != null && params.lastName != ''){
    returnString += 'FN:' + params.firstName + ' ' + params.lastName + '\nN:' + params.lastName + ';' + params.firstName + ';;\n';
  }

  // ---------------
  // optional params
  // ---------------

  if(params.companyName != null && params.companyName != ''){
    returnString += 'ORG:' + params.companyName + '\n';
  }

  if(params.jobTitle != null && params.jobTitle != ''){
    returnString += 'TITLE:' + params.jobTitle + '\n';
  }

  if(params.streetAddress != null && params.streetAddress != ''){
    returnString += 'ADR;HOME:;;' + params.streetAddress + ';' + params.city + ';' + params.region + ';' + params.postalCode + ';' + params.country + '\n';
  }

  if(params.workPhone != null && params.workPhone != ''){
    returnString += 'TEL;WORK:' + params.workPhone.replace('+', '').replace('{', '').replace(')', '').replace('-', '') + '\n';
  }

  if(params.cellPhone != null && params.cellPhone != ''){
    returnString += 'TEL;CELL:' + params.cellPhone.replace('+', '').replace('{', '').replace(')', '').replace('-', '') + '\n';
  }

  if(params.jobTitle != null && params.jobTitle != ''){
    returnString += 'EMAIL:' + params.email + '\n';
  }

  if(params.url != null && params.url != ''){
    returnString += 'URL:' + params.url + '\n';
  }

  // add our closing tag and return
  return returnString += 'END:VCARD'
}

const getLocationString = (string) =>{
return "geo:" + string.split('@')[1].split(',')[0] + ',' + string.split('@')[1].split(',')[1].split(',')[0] + ';u=35'
}
// A data type for passing paramaters for qrcode generation
export class QRCodeParams extends React.Component{

constructor(
    generationMode,
    url = null,
    networkName = null,
    networkType = null,
    networkPassword = null,
    firstName = null,
    lastName = null,
    companyName = null,
    jobTitle = null,
    streetAddress = null,
    city = null,
    region = null,
    postalCode = null,
    country = null,
    workPhone = null,
    cellPhone = null,
    email = null,
    site = null,
    location = null,
    summary = null,
    startDate = null,
    endDate = null,
    text = null){

  super()

  // set values in constructor
  this.generationMode = generationMode;
  this.url = url;
  this.networkName = networkName;
  this.networkType = networkType;
  this.networkPassword = networkPassword;
  this.firstName = firstName;
  this.lastName = lastName;
  this.companyName = companyName;
  this.jobTitle = jobTitle;
  this.streetAddress = streetAddress;
  this.city = city;
  this.region = region;
  this.postalCode = postalCode;
  this.country = country;
  this.workPhone = workPhone;
  this.cellPhone = cellPhone;
  this.email = email;
  this.site = site;
  this.location = location;
  this.summary = summary;
  this.startDate = startDate;
  this.endDate = endDate;
  this.text = text;

}

// properties with defaults

// generic
generationMode = GenerationModes.URL;

// url
url = '';

// wifi
networkName = '';
networkType = 'WPA';
networkPassword = '';

// vcard
firstName = ''
lastName = ''
companyName = ''
jobTitle = ''
streetAddress = ''
city = ''
region = ''
postalCode = ''
country = ''
workPhone = ''
cellPhone = ''
email = ''
site = ''

// location
location = ''

// event
summary = ''
startDate = ''
endDate = ''

// text\
text = ''
}

export class GenerationModes{
  static #_URL = 0
  static #_WIFI = 1
  static #_VCARD = 2
  static #_LOCATION = 3
  static #_EVENT = 4
  static #_TEXT = 5

  static get URL() {return this.#_URL}
  static get WIFI() {return this.#_WIFI}
  static get VCARD() {return this.#_VCARD}
  static get LOCATION() {return this.#_LOCATION}
  static get EVENT() {return this.#_EVENT}
  static get TEXT() {return this.#_TEXT}
}